import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { rgba } from "polished"

const Indicator = styled.div<{ color: string }>`
  position: absolute;
  right: 15px;
  bottom: 7.5px;
  width: 5px;
  height: 5px;
  border-radius: 2.5px;

  ${({ color }) => `
    background-color: ${color};
    color: ${color};`}
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;

  &::before, &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }
  &::before {
    left: -7.5px;
    width: 5px;
    height: 5px;
    border-radius: 2.5px;
    ${({ color }) => `
    background-color: ${color};
    color: ${color};`}
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }

  &::after {
    left: 7.5px;
    width: 5px;
    height: 5px;
    border-radius: 2.5px;
    ${({ color }) => `
    background-color: ${color};
    color: ${color};`}
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }
  
  ${({ color }) => `
    @keyframes dot-flashing {
    0% {
      background-color: ${color};
    }
    50%,
    100% {
      background-color: ${rgba(color, .2)};
    }
  }`}
`;

export const LazyImageIndicator = ({ color, active }: { color: string; active?: boolean }) => {
  return (
    <div
      className={classNames({
        'is-hidden': !active,
      })}
    >
      <Indicator color={color} />
    </div>
  );
};
