export enum Contest {
  Frecam = 'frecam',
  Misscircle = 'misscircle',
}

export function getContestName(type: Contest) {
  switch(type) {
    case Contest.Frecam:
      return "FRESH CAMPUS CONTEST 2021";
    case Contest.Misscircle:
      return "MISS CIRCLE CONTEST 2021";
  }
}

export function getContestBaseUrl(type: Contest) {
  switch(type) {
    case Contest.Frecam:
      return "https://2021.frecam.jp";
    case Contest.Misscircle:
      return "https://2021.misscircle.jp";
  }

}