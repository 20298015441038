import {mount, route} from 'navi';
import { VoteAppRoute } from './vote';
import {LoadingIndicator} from "../styled";
import React from "react";

// https://nifty-tereshkova-240184.netlify.app/?liff.state=%2Fvote%2Ffrecam%2F1%2F2%2F317
export const AppRoute = mount({
  '/': route({getView: () => <LoadingIndicator active />}),
  '/vote': VoteAppRoute,
});
