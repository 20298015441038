import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Contest, getContestBaseUrl, getContestName } from '../domains';

interface ProfileContentProps {
  className?: string;
  children?: React.ReactNode;
  contest: Contest;
  entry_id: number;
  name: string;
  university: string;
  grade: string;
}

export const ProfileContent = ({
  entry_id,
  name,
  university,
  grade,
  contest,
  className,
  children,
}: ProfileContentProps) => {
  const contestName = useMemo(() => getContestName(contest), [contest]);
  const baseUrl = useMemo(() => getContestBaseUrl(contest), [contest]);
  return (
    <div className={classNames('media-content', className)}>
      <div className="content">
        <p className="has-text-centered is-size-7 mb-2">{contestName}</p>
        <p className="has-text-centered has-text-grey">
          <strong className="is-size-4 has-text-black">{name}</strong>
          <br />
          {university}{grade && ` ${grade}` }
        </p>
        {children}
        <div className="control">
          <p className="has-text-centered">
            <a className="button is-text is-size-7" href={`${baseUrl}/entry/${entry_id}`}>
              プロフィールを見る
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
