import axios from 'axios';
import { Contest } from '../domains';
import { useQuery } from 'react-query';

const BaseURL = 'https://soyinka.age.co.jp/entries.php';

const getToken = (type: Contest) => {
  switch (type) {
    case Contest.Frecam:
      return 'FWrCf+pGUVIXyDBFljgA7WaNBY6SqzFMmeeJWri+QZ5Oj1UViM2dNL5pZVErak7o';
    case Contest.Misscircle:
      return 'tzPAtOClmjeEED88+2dpblbzatuqMkSt7GMIwaOEp0HKxjBV5WvUQant61zmr3Kr';
  }
};

const getEntries = async (_: any, type: Contest) => {
  const { data } = await axios.get(BaseURL, {
    headers: {
      'content-type': 'application/json',
      'x-access-token': getToken(type),
    },
  });
  return data.result;
};

export function useEntries(type: Contest) {
  return useQuery(['entries', type], getEntries, { suspense: true });
}

export function useEntry(type: Contest, entry_id: number) {
  const result = useEntries(type);
  const entry = result.data?.filter((entry: any) => entry.entry_id === `${entry_id}`)[0];
  if(!entry) {
    throw new Error(`Entry Not Found (type: ${type}, entry_id: ${entry_id})`);
  }
  return {
    ...result,
    data: entry
  };
}