import React from 'react';
import classNames from 'classnames';
import styled from "styled-components/macro";

interface VoteSectionContainerProps {
  className?: string;
  children?: React.ReactNode;
}

const Section = styled.section`
    .container {
        max-width: 640px;
        z-index: 1;
    }
`;

export const VoteSectionContainer = (props: VoteSectionContainerProps) => {
  return (
    <Section className={classNames('hero', props.className)}>
      <div className="hero-body is-paddingless">
        <div className="container">
          {props.children}
        </div>
      </div>
    </Section>
  );
};
