import {LineUser} from "../domains";

const fetchLineUser = () => {
  const promise = window.liff
    .init({ liffId: '1654349091-A4goBexX' })
    .then(() => Promise.all([liff.getProfile(), liff.getFriendship()]))
    .then(([profile, friendship]) => {
      return {
        'line-access-token': liff.getAccessToken(),
        'line-user-id': profile.userId,
        friend_flag: friendship.friendFlag
      };
    })
    .catch(() => {
      return null;
    });
  return wrapPromise<LineUser | null>(promise);
};

function wrapPromise<T>(promise: Promise<T>) {
  let status = 'pending';
  let result: T;
  let suspender = promise.then(
    (r) => {
      status = 'success';
      result = r;
    },
    (e) => {
      status = 'error';
      result = e;
    }
  );
  return {
    read() {
      if (status === 'pending') {
        throw suspender;
      } else if (status === 'error') {
        throw result;
      } else if (status === 'success') {
        return result;
      }
    },
  };
}

const resource = fetchLineUser();
export function useLineUser() {
  return resource.read() ?? null;
}
