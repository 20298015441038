import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "intersection-observer";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/apm';

Sentry.init({
    dsn: "https://237df63c534c4794855012151f80fa13@o437546.ingest.sentry.io/5400280",
    integrations: [
        new Integrations.Tracing(),
    ],
    tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

