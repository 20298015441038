import axios from 'axios';
import {useMutation} from 'react-query';
import {useState} from 'react';
import {LineUser, VoteState} from '../domains';

const BaseURL = 'https://central-voting-system.age.co.jp';

export function useVote(site_id: number, contest_id: number, entry_id: number, user: LineUser | null) {
  const [voting, setVoting] = useState(false);
  const [state, setState] = useState<VoteState>(VoteState.UNKNOWN);
  const [message, setMessage] = useState<string | undefined>();
  const [timestamp, setTimestamp] = useState<string | undefined>();
  const [vote] = useMutation(
    () => {
      return axios.post(BaseURL + `/throughLiff`, {
        'line-access-token': user?.["line-access-token"],
        'line-user-id': user?.["line-user-id"],
        site_id,
        contest_id,
        entry_id,
      }).then((response: any) => response.data);
    },
    {
      onMutate: () => {
        setVoting(true);
        setState(VoteState.UNKNOWN);
        setMessage(undefined);
      },
      onSuccess: (data: any) => {
        setState(data.state);
        setMessage(data.message);
        setTimestamp(data.timestamp);
      },
      onError: (error) => {
        const message = (error as any).response?.data?.message;
        const timestamp = (error as any).response?.data?.timestamp;
        setState(VoteState.FAILED);
        setMessage(message ?? error.message);
        setTimestamp(timestamp);
      },
      onSettled: () => {
        setVoting(false);
      },
    }
  );
  return {
    vote,
    voting,
    state,
    message,
    timestamp
  };
}
