import React, { useMemo } from 'react';
import { route, mount } from 'navi';
import { queryCache } from 'react-query';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { Contest, getContestName, LineUser } from '../../domains';
import { useEntry, useVote, useLineUser } from '../../hooks';
import {
  FallbackVoteButton,
  ProfileBox,
  ProfileImage,
  RequestFriendshipButton,
  VoteButton,
  VoteSectionContainer,
} from '../../styled';
import { ProfileContent } from '../../styled/ProfileContent';
import { Helmet } from 'react-navi-helmet-async';

export const VoteAppRoute = mount({
  '/:contest/:site_id/:contest_id/:entry_id': route({
    getView: ({ params }) => {
      return (
        <ErrorBoundary
          onReset={() => queryCache.resetErrorBoundaries()}
          fallbackRender={(props) => <ErrorVoteApp {...props} params={params} />}
        >
          <VoteApp
            contest={params.contest as Contest}
            site_id={Number(params.site_id)}
            contest_id={Number(params.contest_id)}
            entry_id={Number(params.entry_id.replace(/^([0-9]+)(.*)/,'$1'))}
          />
        </ErrorBoundary>
      );
    },
  }),
});

interface VoteAppProps {
  contest: Contest;
  site_id: number;
  contest_id: number;
  entry_id: number;
}

const VoteApp: React.FC<VoteAppProps> = ({ contest, site_id, contest_id, entry_id }) => {
  const contestName = useMemo(() => getContestName(contest), [contest]);
  const { data } = useEntry(contest, entry_id);
  const lineUser: LineUser | null = useLineUser();
  const { vote, voting, state, message, timestamp } = useVote(site_id, contest_id, entry_id, lineUser);
  return (
    <>
      <Helmet>
        <title>
          {data.name} {contestName}
        </title>
      </Helmet>
      <VoteSectionContainer>
        <ProfileImage src={data.image} alt={data.name} />
        <ProfileBox className="box">
          <ProfileContent {...data} contest={contest}>
            {lineUser ? (
              lineUser.friend_flag ? (
                <VoteButton
                  className="mt-4 mb-4"
                  state={state}
                  message={message}
                  voting={voting}
                  contest={contest}
                  timestamp={timestamp}
                  onClick={() => vote()}
                />
              ) : (
                <RequestFriendshipButton className="mt-4 mb-4" />
              )
            ) : (
              <FallbackVoteButton
                className="mt-4 mb-4"
                {...data}
                contest={contest}
                site_id={site_id}
                contest_id={contest_id}
              />
            )}
          </ProfileContent>
        </ProfileBox>
      </VoteSectionContainer>
    </>
  );
};

// NOTE:- 投票画面でエラーが発生したときの処理
const ErrorVoteApp: React.FC<FallbackProps & { params: { [name: string]: string }}> = ({ error, resetErrorBoundary, params }) => {
  return (
    <div className="section">
      <div className="container">
        <h1 className="title has-text-danger mb-6 has-text-weight-bold">エラーが発生しました</h1>
        {error?.message && <div className="notification is-danger is-size-7">{error?.message}</div>}
        <div className="notification is-size-7">{JSON.stringify(params, undefined, 2)}</div>
        <button className="button is-warning has-text-weight-bold" onClick={() => resetErrorBoundary()}>ページを再読み込み</button>
      </div>
    </div>
  );
};
