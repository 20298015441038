import React from 'react';
import classNames from 'classnames';
import { LazyImageFull, ImageState } from 'react-lazy-images';
import styled from 'styled-components/macro';
import { LazyImageIndicator } from './LazyImageIndicator';

const radius = '20px';
const Figure = styled.figure`
  border-radius: 0 0 ${radius} ${radius};
  background: linear-gradient(
    to bottom,
    transparent 0%,
    transparent 8.1%,
    rgba(0, 0, 0, 0.001) 15.5%,
    rgba(0, 0, 0, 0.003) 22.5%,
    rgba(0, 0, 0, 0.005) 29%,
    rgba(0, 0, 0, 0.008) 35.3%,
    rgba(0, 0, 0, 0.011) 41.2%,
    rgba(0, 0, 0, 0.014) 47.1%,
    rgba(0, 0, 0, 0.016) 52.9%,
    rgba(0, 0, 0, 0.019) 58.8%,
    rgba(0, 0, 0, 0.022) 64.7%,
    rgba(0, 0, 0, 0.025) 71%,
    rgba(0, 0, 0, 0.027) 77.5%,
    rgba(0, 0, 0, 0.029) 84.5%,
    rgba(0, 0, 0, 0.03) 91.9%,
    rgba(0, 0, 0, 0.03) 100%
  );
  picture img {
    opacity: 0;
    transition: all 0.5s;
    transition-delay: 0.5s;
    &.is-load-success {
      opacity: 1;
    }
  }
`;

interface ProfileImageProps {
  src: string;
}

export const ProfileImage = (
  props: ProfileImageProps & React.ImgHTMLAttributes<HTMLImageElement>
) => {
  return (
    <Figure className={classNames('image is-1by1 is-clipped is-flex', props.className)} style={{overflow: "hidden"}}>
      <picture>
        <LazyImageFull src={props.src} alt={props.alt}>
          {({ imageProps, imageState, ref }) => {
            return (
              <>
                <LazyImageIndicator active={imageState !== ImageState.LoadSuccess} color={'#FD95AC'} />
                <img
                  {...imageProps}
                  className={classNames({
                    'is-load-success': imageState === ImageState.LoadSuccess,
                  })}
                  alt={imageProps.alt}
                  ref={ref}
                />
              </>
            );
          }}
        </LazyImageFull>
      </picture>
    </Figure>
  );
};
