import React from "react";

export const PageNotFound = () => {
  return <section className="hero is-fullheight">
    <div className="hero-body">
      <div className="container" style={{maxWidth: 640}}>
        <h1 className="title">404</h1>
        <h3 className="subtitle">Oops, page not found</h3>
        <div className="buttons">
          <a className="button is-frecam has-text-weight-bold" href="https://www.frecam.jp/">FRESH CAMPUS CONTEST 2021</a>
          <a className="button is-misscircle has-text-weight-bold" href="https://www.misscircle.jp/">MISS CIRCLE CONTEST 2021</a>
        </div>
      </div>
    </div>
  </section>;
};
