import React, { Suspense } from 'react';
import { compose, withView } from 'navi';
import {NotFoundBoundary, Router, View} from 'react-navi';
import HelmetProvider from 'react-navi-helmet-async';
import { ReactQueryDevtools } from 'react-query-devtools';
import { AppRoute } from './apps';
import {LoadingIndicator, PageNotFound} from './styled';

const routesWithLayout = compose(
  withView(() => {
    return (
      <Suspense fallback={<LoadingIndicator active />}>
        <NotFoundBoundary render={PageNotFound}>
          <View />
        </NotFoundBoundary>
      </Suspense>
    );
  }),
  AppRoute
);

function App() {
  let context = {};
  return (
    <div className="app">
      <HelmetProvider>
        <Router routes={routesWithLayout} context={context}>
          <View />
        </Router>
      </HelmetProvider>
      <ReactQueryDevtools initialIsOpen />
    </div>
  );
}

export default App;
