import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components/macro';
import { Contest, getContestBaseUrl, VoteState } from '../domains';

const Control = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  && .button {
    padding-left: calc(1em + 1em);
    padding-right: calc(1em + 1em);
    height: 3em;
  }

  && .icon svg {
    width: 1em;
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
  }
`;

interface VoteButtonProps {
  contest: Contest;
  voting: boolean;
  state: VoteState;
  message?: string;
  timestamp?: string;
}

interface FallbackVoteButtonProps {
  contest: Contest;
  site_id: number;
  contest_id: number;
  entry_id: number;
}

const ReloadIcon = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="redo-alt"
    className="svg-inline--fa fa-redo-alt fa-w-16"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M256.455 8c66.269.119 126.437 26.233 170.859 68.685l35.715-35.715C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.75c-30.864-28.899-70.801-44.907-113.23-45.273-92.398-.798-170.283 73.977-169.484 169.442C88.764 348.009 162.184 424 256 424c41.127 0 79.997-14.678 110.629-41.556 4.743-4.161 11.906-3.908 16.368.553l39.662 39.662c4.872 4.872 4.631 12.815-.482 17.433C378.202 479.813 319.926 504 256 504 119.034 504 8.001 392.967 8 256.002 7.999 119.193 119.646 7.755 256.455 8z"
    />
  </svg>
);

const CheckIcon = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="check-circle"
    className="svg-inline--fa fa-check-circle fa-w-16"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
    />
  </svg>
);

export const VoteButton = (
  props: VoteButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
) => {
  const label = useMemo(() => {
    switch (props.state) {
      case VoteState.COMPLETE:
        return (
          <>
            <span className="icon is-small">
              <CheckIcon />
            </span>
            <span>{props.message ?? '投票が完了しました'}</span>
          </>
        );
      case VoteState.FAILED:
        return (
          <>
            <span className="icon is-small">
              <ReloadIcon />
            </span>
            <span>投票に失敗しました</span>
          </>
        );
      default:
        return '投票する';
    }
  }, [props.state, props.message]);
  return (
    <Control className={classNames('control', props.className)}>
      <button
        className={classNames('button is-rounded has-text-weight-bold', `is-${props.contest}`, {
          'is-loading': props.voting,
          'is-complete': props.state === VoteState.COMPLETE,
          'is-failed': props.state === VoteState.FAILED,
        })}
        disabled={props.state === VoteState.COMPLETE}
        onClick={props.onClick}
      >
        {label}
      </button>
      {props.message && props.state === VoteState.FAILED && (
        <p className="notification is-size-7 px-2 py-2 mt-4 mb-0 is-danger has-text-centered has-text-weight-bold">
          {props.message}
        </p>
      )}
      <Timestamp timestamp={props.timestamp} />
    </Control>
  );
};

function Timestamp({ timestamp }: { timestamp?: string }) {
    const date = timestamp ? new Date(window.parseInt(timestamp, 10) * 1000) : undefined;
    if (!date || !date.getTime()) {
        return null;
    }
    return (
        <p className="is-size-7 mt-3">
            最終投票日時:{' '}
            {`${date.getFullYear() +
            '/' +
            ('0' + (date.getMonth() + 1)).slice(-2) +
            '/' +
            ('0' + date.getDate()).slice(-2) +
            ' ' +
            ('0' + date.getHours()).slice(-2) +
            ':' +
            ('0' + date.getMinutes()).slice(-2)}`}
        </p>
    );
}

export const FallbackVoteButton = (
  props: FallbackVoteButtonProps & React.AnchorHTMLAttributes<HTMLAnchorElement>
) => {
  const baseUrl = useMemo(() => getContestBaseUrl(props.contest), [props.contest]);
  return (
    <Control className={classNames('control', props.className)}>
      <a
        className={classNames('button is-rounded has-text-weight-bold', `is-${props.contest}`)}
        href={`https://central-voting-system.age.co.jp/vote/${props.site_id}/${props.contest_id}/${props.entry_id}?callback=${baseUrl}/entry/${props.entry_id}`}
      >
        投票する
      </a>
    </Control>
  );
};

export const RequestFriendshipButton = ({ className }: { className?: string }) => {
  const [clicked, setClicked] = useState<boolean>(false);
  return (
    <Control className={classNames('control', className)}>
      {clicked ? (
        <>
          <p className="tag is-light has-text-weight-bold mb-4">
            友だち追加の完了後、再読み込みしてください
          </p>
          <button
            className={classNames('button is-rounded has-text-weight-bold', `is-warning`)}
            onClick={() => {
              window.location.reload();
            }}
          >
            <span className="icon is-small">
              <ReloadIcon />
            </span>
            <span>再読み込み</span>
          </button>
        </>
      ) : (
        <>
          <p className="tag is-danger has-text-weight-bold mb-4">
            投票には公式アカウントの友だち追加が必要です
          </p>
          <a
            className={classNames('button is-rounded has-text-weight-bold', `is-warning`)}
            href={`https://line.me/R/ti/p/%40109sdsuc`}
            onClick={() => {
              setClicked(true);
            }}
          >
            公式アカウントを追加
          </a>
        </>
      )}
    </Control>
  );
};
