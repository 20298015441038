import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components/macro';

const Div = styled.div`
  position: relative;
  top: -50px;
  width: 80%;
  margin: 0 auto;
  border-radius: 20px !important;
`;

interface ProfileBoxProps {
  children: React.ReactNode;
  className?: string;
}

export const ProfileBox = (props: ProfileBoxProps) => {
  return <Div className={classNames('box', props.className)}>{props.children}</Div>;
};
